import { request } from '@maxsystems/client'

import GetCarfaxReport from './getCarfaxReport.gql'

export const FetchCarfaxReports = async vins => {
  const res = await request(
    vins.map(({ vin, dealer, user }) => ({
      query: GetCarfaxReport,
      variables: { vin, dealer, user }
    }))
  )
  return res.map(
    ({ data: { vehicle } = {}, errors }) => ({
      ...vehicle,
      ...(errors && { error: errors[0] })
    })
  )
}
