import Vue from 'vue'
import debounce from 'lodash.debounce'

import { client } from '@maxsystems/client'

import {
  FetchCarfaxReports
} from './queries'

export default new Vue({
  data: () => ({
    isCarfaxSnapshotLoaded: false,
    error: null,
    loading: true, // true by default to avoid flash of content
    reports: {},
    toFetch: []
  }),
  watch: {
    toFetch: {
      immediate: true,
      handler: debounce(function (vins) {
        if (!vins.length) return
        this.fetch(vins)
        this.toFetch = []
      }, 50)
    }
  },
  methods: {
    configureClient (config) {
      client.config(config)
    },

    /**
     * Takes an array of vins, fetches the relevant carfax data and caches it
     * @param {Array} vins - vins of vehicles to get Carfax data for
     */
    async fetch (vins) {
      this.loading = true
      const reports = await FetchCarfaxReports(vins)
      for (const { vin, carfax, error } of reports) {
        if (!vin && error) {
          // API error, stopping here
          this.error = error
          break
        }
        // GraphQL doesn't return an error for NO_DATA_VIN, and the vhr component assumes
        // an object with no keys for "No Report Found" cases. This ensures falsey errors
        // produce an empty report object
        this.$set(this.reports, vin, error ? { error } : carfax)
      }
      this.loading = false
    },

    load (vin, buid, uid) {
      if (!vin || vin in this.reports) return
      this.$set(this.reports, vin, null)
      this.toFetch.push({ vin, dealer: buid, user: uid })
    }
  }
})
